/* eslint-disable @typescript-eslint/no-magic-numbers */
export const MILLISECONDS = 1000;
export const SECOND = 1;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;
export const MONTH = 4 * WEEK;
export const YEAR = 12 * MONTH;
/* eslint-enable @typescript-eslint/no-magic-numbers */
